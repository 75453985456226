import React from 'react'
import { BaseLayout } from '@layouts/base'
import { PurchaseApp } from '@components/purchase/PurchaseApp'
import '@styles/purchase.scss'
import { PageProps } from 'gatsby'

const PurchasePage: React.FC<PageProps> = ({ params }) => {
  return (
    <BaseLayout title='FullCalendar Premium Renewal'>
      <PurchaseApp oldDealId={params.dealId} />
    </BaseLayout>
  )
}

export default PurchasePage
